// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_YpSmy{display:flex;flex-direction:column;height:100%;width:100%}.containerEndControls_alNrm{align-items:center;align-self:stretch;display:flex;margin-top:.25rem}.body_pN5un{flex-direction:column}.body_pN5un,.header_D4oJO{display:flex;width:100%}.header_D4oJO{justify-content:space-between}.header_D4oJO .edit_d7oto{cursor:pointer;opacity:0;transition-duration:var(--transition-duration-short);transition-property:opacity;transition-timing-function:ease-in-out}.headerText_Q\\+A\\+l{display:flex;flex-direction:column}.container_YpSmy:hover .edit_d7oto{opacity:1}.icon_l4rbF{align-items:center;justify-content:space-between;width:100%}.cta_S4jnm,.icon_l4rbF{display:flex}.cta_S4jnm{flex-direction:column;height:100%;justify-content:flex-end;padding-top:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_YpSmy",
	"containerEndControls": "containerEndControls_alNrm",
	"body": "body_pN5un",
	"header": "header_D4oJO",
	"edit": "edit_d7oto",
	"headerText": "headerText_Q+A+l",
	"icon": "icon_l4rbF",
	"cta": "cta_S4jnm"
};
module.exports = ___CSS_LOADER_EXPORT___;
